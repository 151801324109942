import React, { useCallback, useState } from 'react';
import { Provider } from '@teamleader/i18n';

import Context from './Context';

interface ProviderProps {
  children: React.ReactNode;
}

const SUPPORTED_LANGUAGES = ['de', 'en', 'es', 'fr', 'it', 'nl'];

const I18nProvider: React.FC<ProviderProps> = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState<string | null>(null);

  const getPath = useCallback((locale: string) => {
    if (__CONFIG__.enableKlingon) {
      setCurrentLocale('en');
      return `/translations.json`;
    }

    if (SUPPORTED_LANGUAGES.includes(locale)) {
      setCurrentLocale(locale);
      return `/translations/${locale}.json`;
    }

    const slug = locale.split('-')[0];
    if (SUPPORTED_LANGUAGES.includes(slug)) {
      setCurrentLocale(slug);
      return `/translations/${slug}.json`;
    }

    setCurrentLocale('en');
    return `/translations/en.json`;
  }, []);

  return (
    <Provider path={getPath} namespace="domains.auth" locale={__CONFIG__.localeOverwrite || navigator.language}>
      <Context.Provider value={currentLocale}>{children}</Context.Provider>
    </Provider>
  );
};

export default I18nProvider;
